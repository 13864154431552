import { useNavigate } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"
import { VideoUtils } from "../../utils/ImageUtils"
import { DEFAULT_IMAGE } from "../GravatarAPI"
import MenuOptions, { MusicUploadModal, NftokenCollectionModal, PlaylistModal, ProfileModal, MiscellaneousOptions } from "../MenuOptions"
import { useModal } from "../Modal/ModalContext"

	export const Welcome = {
		signButton: true,
		navLink: false,
		search: false,
		bars: true,
		links:[
			
		{
			id: "store",
			title: "Music Store",
			to: "/store"
        
		},
		/* {
			id: "about",
			title: "About",
			to: "/about"
		}, */
		
		{
			id: "tdRequirements",
			title: "Token Distribution Requirements",
			to: "/TDRequirements"
		},
		
		{
			id: "whitepaper",
			title: "Whitepaper",
			to: "#whitepaper",
			file: true
		},
		{
			id: "eligibilityChecker",
			title: "Eligibility Checker",
			to: "/eligibilityChecker"
		},
		{
			id: "createTL",
			title: "Add Supported Trustlines",
			to: "/createTL"
		},
		{
			id: "setEmailHash",
			title: "Account Set Email Hash",
			to: "/setEmailHash"
		},
		{
			id: "musicDownloader",
			title: "Music Downloader",
			to: "/musicDownloader"
		},
		{
			id: "signup",			
			title: "Sign Up",
			to: "access/signup"
		}
		],
		
	}
	
	export const Access = { signButton: false, notifications: false, bars: false }
	
	
	export function Home (uName) {
		const {video1} = VideoUtils()

		const defaultImg = DEFAULT_IMAGE()
		const {currentUser,gravatarInfo} = useAuth()
		const {openModal, modalContent, toggleModal, closeModal} = useModal()
		//const navigate  = useNavigate()
		const nav = {
		notifications: true,
		bars: true,
		navLink: true,
		search: true,
		uploads: true,
		profile: {
			img: gravatarInfo?.thumbnailUrl ?  gravatarInfo?.thumbnailUrl : defaultImg , 
			alt: uName},
			links: 
			[	
				{
					id: "home",
					title: "Home",
					to: `/home`
				},
				{
					id: "profile",
					title: "Profile",
					onClick: () =>{
						openModal();
					
						return modalContent(<ProfileModal />);
					  },
					to: `/u/${currentUser?.uid}`
				},

				{
					id: "music",
					title: "Music Store",
					to: "/store"
				},
				{
					id: "playlist",
					onClick: () => {
						openModal();
						modalContent(
						  <>
							<PlaylistModal />
						  </>
						);
					} ,
					title: 'My Playlists',
					to: '#myplaylists'
				  },
				  {	id: "upload",
					onClick:  () => {
						openModal();
						modalContent(
						  <>
							<MusicUploadModal />
						  </>
						);
					} ,
					title: "Upload",
					to: '#upload'
				  },
				  {
					id: "nftCreator",
					onClick: 
						() => {
							openModal()
							modalContent(<NftokenCollectionModal/>)
						  }
					,
					title: "NFT Creator",
					to: '#NFTCreator',
				  },
				  {
					id: "more",
					onClick:  () => {
						openModal();
						modalContent(
						  <>
							<MiscellaneousOptions />
						  </>
						);
					},
					title: "More",
					to: '#more'
				  },
				
	
			{
			id: "signout",			
			title: "Sign Out",
			to: "/"
		}]}
		return nav
	}
	
	


