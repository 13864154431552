import React, { useEffect, useState } from "react";
import { CustomButton } from "../../ButtonElement";
import ContentWrapper from "../../ContentWrapper";
import { FormButton, FormInput1, FormText, FormWrapper } from "../../CustomForm/CustomFormElements";
import { Header } from "../../Header";
import { Heading, Subtitle } from "../../InfoSection/InfoElements";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { useModal } from "../../Modal/ModalContext";
import { Nav, NavContainer, NavLink } from "../../NavLink/NavLinkElements";
import OptionModal from "../../OptionModal";
import { Text, TextRoute } from "../../QualificationRequirements/QualificationRequirementsElements";
import ScrollToTop from "../../ScrollToTop";
import { ArtistNavImg, ArtistRegistrationNav, ArtistRegistrationNavLink } from "../RegistrationFormElements";
import ArtistAccountClaimer from "./ArtistAccountClaimer";
import RegistrationForm from "./RegistrationForm";
import QRCodeForm from "../../QRCodeForm";
import { TRANSACTION_OPTIONS } from "../../../utils/Enum";
import { Theme } from "../../WebsiteThemeElements";
import { downloadFile } from "../../../utils";
import ImageUtils from "../../../utils/ImageUtils";
import AppBanner from "../../AppBanner";
import { XummApp } from "../../AppBanner/Data";



const ArtistRegistration = () => {
  const { goTo } = ScrollToTop();
  const {artistRegistrationClaim, artistRegistrationCreate, labelRegistrationCreate} = ImageUtils()
  const [regType, setRegType] = useState()
  const {openModal, modalContent, closeModal} = useModal()
  const [address, setAddress] = useState()


  useEffect(() => {
   if(window.location.href.includes('?reg=claim')){
    setRegType('claim')
    return () => {}
   }
   if(window.location.href.includes('?reg=create')){
    setRegType('create')
    return () => {}
   }
   setRegType()
   return () => {}
  }, [])

  const onSignInFail = (err) => {

  }

  const onSignInSuccess = (data, type) => {
    //console.log(data)
    //console.log(type)
    if(data?.artist){
      modalContent(
      <OptionModal heading={'Artist account already assigned to wallet'}>
        <HoverOption onClick={() => {
          closeModal()
          modalContent()
        }}>OK</HoverOption>
      </OptionModal>)
      return
    }else{
      if( data?.emailHash && data?.isGravatarVerified && data?.gravatar && data?.registration){
        localStorage.setItem('artistReg', data.address)
        setAddress(data?.address)
        if(type ==='claim'){
            goTo(`?reg=claim`)
            closeModal()
            modalContent()
            return
          }
          if(type === 'create'){
            goTo(`?reg=create`)
            closeModal()
            modalContent()
            return
          }
        
      }else{
      modalContent(<OptionModal heading={'Requirements'} additionalText={`Before you can continue, complete the following`}>
         {!data?.registration && (
                <FormButton
                  style={{
                    marginTop: "2%",
                    border: "1px solid " + Theme({}).secondaryBg,
                    background: "transparent",
                  }}
                  transparent={false}
                  to={"#"}
                  onClick={() => {
                    goTo("/access/signup");
                  }}
                >
                  Sign Up
                </FormButton>
              )}
              {!data?.emailHash && (
                <FormButton
                  style={{
                    marginTop: "2%",
                    background: Theme({}).accentColor,
                  }}
                  transparent={false}
                  to={"#"}
                  onClick={() => {
                    goTo("/setEmailHash");
                  }}
                >
                  Set Email Hash
                </FormButton>
              )}
              {!data?.gravatar && (
                <FormButton
                  style={{
                    marginTop: "2%",
                    color: Theme({}).infoColor,
                    border: "1px solid " + Theme({}).infoColor,
                    background: "transparent",
                  }}
                  transparent={false}
                  to={"#"}
                  onClick={() => {
                    downloadFile("https://en.gravatar.com");
                  }}
                >
                  Sign up for Gravatar
                </FormButton>
              )}
              {data?.gravatar && !data?.isGravatarVerified &&(
                <FormButton
                  style={{
                    marginTop: "2%",
                    color: Theme({}).warningColor,
                    border: "1px solid " + Theme({}).warningColor,
                  }}
                  transparent={false}
                  to={"#"}
                  onClick={() => {
                    downloadFile("https://en.gravatar.com/profiles/edit#verified-services");
                  }}
                >
                  Verify Gravatar Account
                </FormButton>
              )
             
              }
        
      </OptionModal>) 
      return
    }
  }

  }

  const signIn = (type) => {
    openModal()
    modalContent(<QRCodeForm 
      heading={'Account Sign In'}
      type={TRANSACTION_OPTIONS.ARTIST_PROFILE_CHECKER} 
      body={{
        instruction: `User is Signing transaction to ${type} an artist account`,
        memo: `User is Signing transaction to ${type} an artist account`,
        type: type
      }}
      onSuccess={onSignInSuccess}
            onFail={onSignInFail}
            cancelQr={() => {
                closeModal()
                modalContent()
            }}
    
    />)
  };

  const regRequirements = (type) => {
    openModal()
    modalContent(<OptionModal heading={'Artist Registration Requirements'}>
      <FormWrapper>
        <Text>1. A non-custodial XRP wallet address, using the Xumm wallet.</Text>
        <Text onClick={() => {closeModal()
          modalContent()}}>2. You have successfully <TextRoute to={'/access/signup'}>signed up</TextRoute> for the platform. To learn more visit the <TextRoute to={'/faq'}>FAQ page</TextRoute></Text>
        <Text>3. An email address that is signed up to <a style={{color: Theme({}).secondaryColor}}  target="_blank" rel="noopener noreferrer" href={'https://en.gravatar.com'}>Gravatar</a> preferrably the address linked to your wallet</Text>
        <FormText>(The gravatar account should have at least 2 <a style={{color: Theme({}).secondaryColor}}  target="_blank" rel="noopener noreferrer" href={"https://en.gravatar.com/profiles/edit#verified-services"} > verified services</a> assigned to it eg. Twitter, Github, Wordpress etc.)</FormText>
      </FormWrapper>
    <HoverOption onClick={() => {
      signIn(type)
    }}>I Am Ready To Continue</HoverOption>
    <CustomButton onClick={() => {
      closeModal()
      modalContent()
    }}>Cancel</CustomButton>
    </OptionModal>)
  }
  


  return (
    <>
    <AppBanner {...XummApp}/>
     {(!window.location.href.includes('?reg=create') && !window.location.href.includes('?reg=claim')) && 
     <><Header title={'Get Full Access to Sonar Muse for Artists'}/>
      <ContentWrapper>
    <Subtitle style={{ color: "white" }}>
          Select account registration type
        </Subtitle> 
        <div style={{ margin: "10% 0%", padding: "0", width: "100%" }}>
          <NavContainer>
            <ArtistRegistrationNav>
              <ArtistRegistrationNavLink
                onClick={() => regRequirements(`claim`)}
              >
              
                <ArtistNavImg
                    onClick={() => {}}
                    isRounded
                    size={"8rem"}                     
                    albumArt={artistRegistrationClaim}
                  />  
                  Claim Existing Artist Page
              </ArtistRegistrationNavLink>
              <ArtistRegistrationNavLink
                onClick={() => regRequirements(`create`)}
              >
                <ArtistNavImg
                    onClick={() => {}}
                    isRounded
                    size={"8rem"}                    
                    albumArt={artistRegistrationCreate}
                  />
                Create New Artist Page
              </ArtistRegistrationNavLink>{" "}
            </ArtistRegistrationNav>
          </NavContainer>
        </div>
        
      </ContentWrapper></>}
      {window.location.href.includes('?reg=claim') 
        && <ArtistAccountClaimer/>}
        {window.location.href.includes('?reg=create')
        && <RegistrationForm artist={{profile:{
          uid:address
        }}} />}
    </>
  );
};

export default ArtistRegistration;
