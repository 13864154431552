import React, { useState } from 'react'
import AppBanner from '../../AppBanner'
import { XummApp } from '../../AppBanner/Data'
import { Button, CustomButton } from '../../ButtonElement'
import { ButtonWrapper } from '../../ContentList/ContentListElements'
import PageIndexer from '../../PageIndexer'
import { FormInput1, FormLabel, FormText, FormWrapper } from '../../CustomForm/CustomFormElements'
import { Header } from '../../Header'
import ContentWrapper from '../../ContentWrapper'
import { SmallText } from '../../NFTCollectionCreator/NFTCollectionCreatorElements'
import { ALERT_TYPES, PODCASTER_FORM_INPUT_TYPES, TRANSACTION_OPTIONS } from '../../../utils/Enum'
import { SongDetailsText } from '../../MusicPage/MusicPageElements'
import { useAlert } from '../../Alert/AlertContext'
import ScrollToTop from '../../ScrollToTop'
import { useModal } from '../../Modal/ModalContext'
import QRCodeForm from '../../QRCodeForm'
import { emailRegex } from '../../../utils/Regex'
import { getObjectLength } from '../../../utils'
import OptionModal from '../../OptionModal'
import { HoverOption } from '../../MenuOptions/MenuOptionsElements'
import Footer from '../../Footer'

const PodcasterRegistration = () => {
    const {openModal, closeModal, modalContent} = useModal()
    const {addAlert} = useAlert()
    const {goTo} = ScrollToTop()
    const [podcasterName, setName] = useState('')
    const [fullName, setFullName] = useState('')
    const [address, setAddress] = useState('')
    const [accInfo, setAccInfo] = useState()
    const [paymentPointer, setPaymentPointer] = useState('')
    const [email, setEmail] = useState('')
    const [nftStorageApiKey, setNftStorageApiKey] = useState('')
    const [bio, setBio] = useState('')
    const [errors, setErrors] = useState({})
    const [currentPage, setCurrentPage] = useState(1)

    const handleInput = (e) => {
        switch (e.target.name) {
            case PODCASTER_FORM_INPUT_TYPES.NAME:
                setName(e.target.value)
                
                break;
            case PODCASTER_FORM_INPUT_TYPES.FULL_NAME:
                setFullName(e.target.value)

                break;
            case PODCASTER_FORM_INPUT_TYPES.EMAIL:
                setEmail(e.target.value)

                break;
            case PODCASTER_FORM_INPUT_TYPES.PAYMENT_POINTER:
                setPaymentPointer(e.target.value)
                break;
        
            default:
                break;
        }
    }

    const onAddressSuccess = (accInfo) => {
        //console.log(accInfo)
        setAddress(accInfo?.account?.Account)
        closeModal()
        modalContent()
    } 

    const onAddressFail = () => {
        closeModal()
        modalContent()
    }

    const changeAddress = () => {
        openModal()
        modalContent(<QRCodeForm 
            heading={'Podcaster Address Registration'}
            type={TRANSACTION_OPTIONS.CHANGE_ARTIST_LABEL_ADDRESS}
            body={{
                memo: address ? 'Podcaster is signing transaction to change login wallet address' : 'Podcaster is signing transaction to add login wallet address' ,
                instruction: address ? 'Podcaster is signing transaction to change login wallet address' : 'Podcaster is signing transaction to add login wallet address' ,
            }}
            onSuccess={onAddressSuccess}
            onFail={onAddressFail}
            cancelQr={() => {
                closeModal()
                modalContent()
            }}
            />)
    }

    const verifyInfo = () => {
        let err = {}
        setErrors()
        if(podcasterName?.trim()?.length === 0){
            err = {
                ...err,
                name: 'Podcaster name cannot be empty'
            }
        }
        if((email?.trim()?.length === 0) || !emailRegex.test(email)){
            err = {
                ...err,
                email: 'Please enter a valid email address'
            }
        }
        if(paymentPointer?.trim().length > 0 && !paymentPointer.includes('ilp.')){
            err = {
                ...err,
                paymentPointer: 'Payment Pointer is invalid'
            }
        }
        if((address?.trim()?.length === 0)){
            err = {
                ...err,
                walletAddress: 'Please add a wallet address'
            }
        }
        setErrors(err)
        if(getObjectLength(err) === 0){
            return true
        }
        if(getObjectLength(err) > 0){
            return false
        }
    }

    const onRegistrationFail = () => {
        closeModal()
        modalContent()
    }


    const onRegistrationSuccess = () => {
        goTo('/home')
        modalContent(<>
            <OptionModal heading={'Podcaster Account Registration'}>
                <FormText>Your podcaster {'registration'} is being processed. You will receive an email shortly. </FormText>
                <HoverOption onClick={() => {
                    closeModal()
                    modalContent()
                }}>Ok</HoverOption>
            </OptionModal>
        </>)
        
    }



    const submitCreate = () => {
       if(verifyInfo()){
        openModal()
        modalContent(<QRCodeForm 
            heading={'Podcaster Account Registration'}
            type={TRANSACTION_OPTIONS.PODCASTER_REGISTRATION}
            body={{
                sender: address,
                accountInfo: {
                    name: podcasterName,
                    uid: address,
                    email: email,
                    paymentPointer: paymentPointer,
                    fullName: fullName,
                    nftStorageAPIKey: nftStorageApiKey,
                    bio: bio.trim().length > 0 ? bio : undefined,

                },
                memo:'Podcaster is signing transaction to register account for ' + podcasterName ,
                instruction:'Podcaster is signing transaction to register account for ' + podcasterName ,
            }}
            onSuccess={onRegistrationSuccess}
            onFail={onRegistrationFail}
            cancelQr={() => {
                closeModal()
                modalContent()
            }}
            />)
       }else{
        addAlert({
            title: "Podcaster Registration",
            type: ALERT_TYPES.WARNING,
            message: 'Please fill in form',
            secs: 10,
          });
       }
    }

    return (
        <>
        <Header title={'Podcaster Registration'}/>
        <ContentWrapper>
          { currentPage === 1 && <>
          
          <FormWrapper>
                <FormLabel>Podcaster Name</FormLabel>
                <FormInput1 name={PODCASTER_FORM_INPUT_TYPES.NAME} value={podcasterName} onChange={handleInput} placeholder={'Podcaster Name'}/>
                <SmallText>Podcaster's Stage Name. This will be used for displaying in your profile and music</SmallText>
                {errors?.name && <SongDetailsText error >{errors?.name}</SongDetailsText>}
            </FormWrapper>
            <FormWrapper>
            <FormLabel>Full Name (Optional)</FormLabel>
                <FormInput1 name={PODCASTER_FORM_INPUT_TYPES.FULL_NAME} value={fullName} onChange={handleInput} placeholder={'Full Name'}/>
                <SmallText>Podcaster's full (real) name. This will be used for any music credits</SmallText>
            </FormWrapper>
            <FormWrapper>
            <FormLabel>Email Address</FormLabel>
                <FormInput1 name={PODCASTER_FORM_INPUT_TYPES.EMAIL} type={"email"} value={email} onChange={handleInput} placeholder={'Email Address'}/>
                <SmallText>Email Address to cantact podcaster. Gravatar account information from this address will be used within the podcaster profile </SmallText>
                {errors?.email && <SongDetailsText error>{errors?.email}</SongDetailsText>}
            </FormWrapper>
                   
            </>}
    
            {currentPage === 2 && 
                <> <FormWrapper>
                <FormLabel>Wallet Address</FormLabel>
                    <FormInput1 name={PODCASTER_FORM_INPUT_TYPES.ADDRESS} readOnly value={address} placeholder={'Wallet Address'}/>
                    <Button onClick={() => changeAddress()}>{address ? `Change Address` : `Sign In` }</Button>
                    <SmallText>A wallet address that will be used to access the podcaster page . This will be used for receiving payments and signing podcaster related transactions</SmallText>
    
                    {errors?.walletAddress && <SongDetailsText error>{errors?.walletAddress}</SongDetailsText>}
                </FormWrapper>
                <FormWrapper>
                <FormLabel>Payment Pointer (Optional)</FormLabel>
                    <FormInput1 name={PODCASTER_FORM_INPUT_TYPES.PAYMENT_POINTER} value={paymentPointer} onChange={handleInput} placeholder={'Payment Pointer'}/>
                    <SmallText>This will be used for receiving micropayments and tips using the Interledger Protocol</SmallText>
                    {errors?.paymentPointer && <SongDetailsText error>{errors?.paymentPointer}</SongDetailsText>}
                </FormWrapper>
                </>
            }
            {currentPage === 3 && 
                <><FormWrapper>KYC Coming Soon</FormWrapper></>
            }
            <PageIndexer onClick={setCurrentPage} pages={2} currentPage={currentPage}/>
            <ButtonWrapper>
                <Button onClick={() => submitCreate()}>Create Account</Button>
                <CustomButton onClick={() => {goTo('/podcasters/registration')}}>Cancel</CustomButton>
            </ButtonWrapper>
        </ContentWrapper>
        
        </>
      )
    }


export default PodcasterRegistration