import React, { useEffect } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaTelegram,
  FaLinkedin,
} from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import logo from "../../images/sonar_muse_logo_nav.png";
import logoInvert from "../../images/sonar_muse_logo_nav_invert.png";
import { useAuth } from "../../contexts/AuthContext";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinkWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  Img,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";
import smPdf from "../../files/Sonar_Muse_Whitepaper.pdf";
import smPdf2 from "../../files/Sonar_Muse_Onboarding_Flow.pdf";
import { UnavailableFeature } from "../MenuOptions";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";
import { useModal } from "../Modal/ModalContext";

const Footer = () => {
  const { currentUser, logout } = useAuth();
  const { theme } = useWebsiteTheme();
  const { modalContent, openModal, closeModal } = useModal();
  const downloadFile = () => {
    window.location.href = smPdf;
  };
  const downloadFile2 = () => {
    window.location.href = smPdf2;
  };

  useEffect(() => {
    return () => {};
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
    return;
  };
  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <FooterLinkTitle style={{ fontSize: "1.4rem" }}>
            Sonar Muse
          </FooterLinkTitle>

          <FooterLinksContainer>
            <FooterLinkWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Get Started</FooterLinkTitle>
                {!currentUser?.uid && (
                  <FooterLink to="/access/signup">Sign Up</FooterLink>
                )}
                {currentUser?.uid && (
                  <FooterLink to="/" onClick={logout}>
                    Sign Out
                  </FooterLink>
                )}
              </FooterLinkItems>
            </FooterLinkWrapper>
            <FooterLinkWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>About</FooterLinkTitle>
                {/* <FooterLink to="/about" onClick={toggleHome}>
                  About Us
                </FooterLink> */}
                <FooterLink
                  to="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={downloadFile}
                >
                  Whitepaper
                </FooterLink>
                <FooterLink
                  to="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={downloadFile2}
                >
                  Onboarding Flow
                </FooterLink>
                <FooterLink to="/faq" onClick={toggleHome}>
                  FAQ
                </FooterLink>
              </FooterLinkItems>
            </FooterLinkWrapper>
            <FooterLinkWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Resources</FooterLinkTitle>

                <FooterLink to="/contactUs" onClick={toggleHome}>
                  Contact Us
                </FooterLink>
                <FooterLink to="/eligibilityChecker">
                  Eligibility Checker
                </FooterLink>
                {!currentUser?.uid && (
                  <FooterLink to="/setEmailHash">Set Up Email Hash</FooterLink>
                )}
                <FooterLink to="/createTL" onClick={toggleHome}>
                  Add Supported Trustlines
                </FooterLink>
                <FooterLink to="/musicDownloader" onClick={toggleHome}>
                  Music Downloader
                </FooterLink>
              </FooterLinkItems>
            </FooterLinkWrapper>
            <FooterLinkWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Legal</FooterLinkTitle>

                <FooterLink
                  onClick={() => {
                    openModal();
                    modalContent(<UnavailableFeature />);
                  }}
                  to="#"
                >
                  Terms of Service
                </FooterLink>
                <FooterLink
                  onClick={() => {
                    openModal();
                    modalContent(<UnavailableFeature />);
                  }}
                  to="#"
                >
                  Anti-Fraud Policy
                </FooterLink>

                <FooterLink
                  onClick={() => {
                    openModal();
                    modalContent(<UnavailableFeature />);
                  }}
                  to="#"
                >
                  Privacy Policy
                </FooterLink>
                <FooterLink
                  onClick={() => {
                    openModal();
                    modalContent(<UnavailableFeature />);
                  }}
                  to="#"
                >
                  Cookie Preferences
                </FooterLink>
              </FooterLinkItems>
            </FooterLinkWrapper>
            <FooterLinkWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>See Also</FooterLinkTitle>

                <FooterLink
                  onClick={() => {
                    openModal();
                    modalContent(<UnavailableFeature />);
                  }}
                  to="#"
                >
                  Partners
                </FooterLink>

                <FooterLink
                  onClick={() => {
                    openModal();
                    modalContent(<UnavailableFeature />);
                  }}
                  to="#"
                >
                  Support
                </FooterLink>
                <FooterLink
                  onClick={() => {
                    openModal();
                    modalContent(<UnavailableFeature />);
                  }}
                  to="#"
                >
                  Press Kit
                </FooterLink>
              </FooterLinkItems>
            </FooterLinkWrapper>
          </FooterLinksContainer>
          <SocialMedia>
            <SocialMediaWrap>
              <SocialLogo
                to={currentUser?.uid ? "/home" : "/"}
                onClick={toggleHome}
              >
                <Img
                  src={theme === "dark" ? logo : logoInvert}
                  alt="Sonar Muse"
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                />
              </SocialLogo>
              <WebsiteRights>
                © Sonar Muse {new Date().getFullYear()} All rights reserved
              </WebsiteRights>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.linkedin.com/in/sonar-muse"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Linkedin"
                >
                  <FaLinkedin />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.instagram.com/sonarmuse_xrpl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink
                  href="https://twitter.com/SonarMuse"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Twitter"
                >
                  <FaTwitter />
                </SocialIconLink>
                <SocialIconLink
                  href="https:/t.me/SonarMuse"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Telegram"
                >
                  <FaTelegram />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};
export default Footer;
