import React, {useEffect, useState} from 'react'
import axios from '../Authorization/client'
import { useNavigate} from 'react-router'
import WebSocket from "react-websocket";
import { ThemeProvider } from 'styled-components'
import { useAuth } from '../../contexts/AuthContext'
import LoadingScreen, {  } from '../LoadingScreen'
import Navbar from '../Navbar'
import { Access } from '../Navbar/Data'
import { 
  Container,
  FormButton,
  FormContent, 
  FormH1,
  Form, 
  FormWrap, 
  QrCode, 
  QrWrapper, 
  Small, 
  Text, 
  TextLink, 
  TextRoute, 
  VideoBg,
  ContentWrapper} from './SignUpElements'
import { signInWithCustomToken } from '@firebase/auth';
import { auth } from '../Authorization/firebase';
import { VideoUtils } from '../../utils/ImageUtils';
import Video from '../../videos/video1.mp4'
import { getObjectLength } from '../../utils';
import { getSignTxStatus } from '../../utils/dbUtils/signTxUtils';
import { FormButton1 } from '../CustomForm/CustomFormElements';


const Login = () => {
    const {video1} = VideoUtils()
    const [errMsg, setError] = useState()
    const [msg, setMessage] = useState('')
    const [invoice, setInvoice] = useState();
    const [status, setStatus] = useState("idle");
    const [response, setResponse] = useState({})  
    const [isLoaded, setLoaded] = useState(false)
    const [isOpened, setOpened] = useState(false)
    const [payload, setPayload] = useState()
    //const [isConfirmed, setConfirmed] = useState(false)
    const navigate  = useNavigate()
    const {login, currentUser} = useAuth()

    useEffect(() => {
      if(invoice){
            getSignTxStatus(invoice, status, setStatus, setResponse)
  
      }
      return () => {
      }
    }, [invoice])
    
    useEffect(() => {
      if(invoice){
        if(status === "success" && response !== false){
          console.log(response)
          VerifySignIn(response)
      }if(status === "error"){
        setLoaded(false);
        setPayload();
        setError(
          "The sign in failed. Please reload the page and try again"
        );
      }}
      return () => {
  
      }
    }, [status, invoice])
  
    const SignIn = async () => {
       return await axios.get('/api/xummQrCode').then(res => {
          if (!res?.data?.error && res?.data?.payload) {
            setInvoice(res.data.invoice)
            setPayload(res.data.payload);
            setLoaded(true);
          } else {
            setError(
              "Oops! Something wrong happened. Please reload the page"
            );
            setLoaded()
          }
          
        }).catch(err => {
          //console.log(err)
          setError('Oops! Something wrong happened. Please reload the page')
          setLoaded()
          
        })
      };

    const updateSignInStatus = async (messageData) => {
        //console.log('Message Data: ', messageData)
        const data = JSON.parse(messageData);
        if (data.opened === true) {
          setOpened(true);
          setMessage("Wating for you to sign transaction");
        }
        if (isOpened === true && data.signed === true) {
          //VerifySignIn(payload.uuid);
          setLoaded(false)
          setError('')
          //console.log("Signed")
        } else if (isOpened === true && data.signed === false) {
          setLoaded(false);
          setPayload({});
          setError(
            "The sign in was rejected. Please reload the page and try again"
          );
        }
        if(data?.expired === true){
          setLoaded(false);
          setPayload();
          setError(
            "The transaction has expired. Please reload the page and try again"
          );
          setOpened(false) 
          }
        if(data?.signed === false) {
          setLoaded(false);
          setPayload();
          setError(
            "The transaction was rejected. Please reload the page and try again"
          );
          setOpened(false) 
        }
      };



        //Verify xumm payload sign in by user
  //Get users XRP address info (Email Hash and Account Data)
  const VerifySignIn = async ({address, userToken}) => {
    //console.log('uuid', uuid
    //console.log('address', address)
    localStorage.setItem('userToken',userToken)
    localStorage.setItem('accInfo', address)
    axios.get(`/api/userAddressInfo/${address}`).then(info => {
      
      let data = info.data
      if(data?.accInfo)
      {
        let accInfo = data.accInfo
        login(accInfo.email, address, accInfo.gravatarUrl).then(res => {
         
          if(res?.token)
        {
          signInWithCustomToken(auth, res.token ).then((user) => {
          localStorage.setItem('user', JSON.stringify(accInfo))
          navigate( '/home')
        }).catch(err => {
          setError("Something went wrong. please reload the page")
        })}
        else{
          setError(res.message)
        }
      }).catch(err => {
        //console.log(err)
        setError("Oops! Something wrong happened. Please reload the page")
      })
        }
        else if(data === false)
      {
        setPayload()
        setLoaded()
        setError("Oops! Something wrong happened. Please reload the page")
      }
  }).catch(err => {
    //console.log(err)
    setLoaded(true)
    setError("Could not sign in. Please ensure that credentials are valid")
  })

};

const validateTx = async() => {
  setLoaded(false)
  return await axios
        .post("/api/verifyTxPayload", { uuid: payload?.uuid }).then((verification) => {
          //console.log(verification)
          if(verification?.data && !verification?.data?.error)
          {
            setLoaded(false);
            //dispatch(verification?.data);
            getSignTxStatus(invoice, status, setStatus, setResponse)
            setPayload();
            setError("The transaction went through successfully.");
            setOpened(false); //console.log("Signed")
            return
          }else{
            setLoaded(true)
            setError("The transaction was not signed.");
            return
          }
        }).catch(err => {
          console.error(err)
          setLoaded(true)
          setError("Something went wrong. Please try again");
        }) 
     
}

const refreshPage = () => {
  setPayload({});
  setError();
  setInvoice()
  setStatus("idle")
  setResponse()
  setOpened(false);
  setLoaded(false);
  SignIn();
};

  useEffect(() => {
    if(currentUser?.uid)
    {
      navigate( '/home')
    }else{
      SignIn()
    }
    
    setLoaded(false)
    setPayload({})
    setOpened(false)
    setError()
     
    return () => {}
    
  },[])
    
        //console.log("Websocket: ",payload.refs.websocket_status)
        return (
          <>
            <Navbar navInfo={Access} />

              <Container>
              <VideoBg autoPlay loop muted src={video1}
                    type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
                </VideoBg>
                <ContentWrapper>
               {isLoaded === true && getObjectLength(payload) > 0 && !errMsg &&
               <><WebSocket
                url={payload.refs.websocket_status}
                onMessage={(data) => updateSignInStatus(data)}
              />
                <FormWrap>
                  <FormContent>
                    <Form action={(e) => e.preventDefault}>
                      <FormH1> Sign In </FormH1>
                      <QrWrapper>
                        <QrCode src={payload.refs.qr_png} />
                      </QrWrapper>
                      {isOpened === false && (
                        <Text>
                          Scan QR code or click {" "}
                          <TextLink href={payload.next.always} target="_blank" rel="noopener noreferrer">here</TextLink> to go
                          to your Xaman wallet
                        </Text>
                      )}
                      {isOpened === true && (
                        <Text>
                          <Text>{msg}</Text>
                        </Text>
                      )} 
                      <FormButton1 onClick={() => validateTx()} transparent>
                      I Already Signed Transaction
                    </FormButton1>
                    <FormButton transparent={false} to='/access/signin' onClick={refreshPage}>Reload</FormButton>
                  <Small>Don't have an account? <TextRoute to='/access/signup'>Sign up</TextRoute></Small>

                    </Form>
                  </FormContent>
                </FormWrap>
                </>
                }{errMsg && 
              
                <FormWrap>
                  <FormContent>
                    <Form>
                      <Text>{errMsg}</Text>
                      <FormButton transparent={true} to='/access/signin' onClick={refreshPage}>Reload</FormButton>
                    </Form>
                  </FormContent>
                </FormWrap>
            }
            {!errMsg && !isLoaded && <LoadingScreen transparent={true} isLoaded={isLoaded} />}
            </ContentWrapper>
              </Container>
            
          </>
        
           
          
        )
}

export default Login