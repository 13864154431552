import React, { useState } from 'react'
import { getObjectLength } from '../../../utils'
import { ALERT_TYPES, ARTIST_FORM_INPUT_TYPES, TRANSACTION_OPTIONS } from '../../../utils/Enum'
import { emailRegex, IlpRegex } from '../../../utils/Regex'
import { useAlert } from '../../Alert/AlertContext'
import { Button, CustomButton } from '../../ButtonElement'
import { ButtonWrapper } from '../../ContentList/ContentListElements'
import ContentWrapper from '../../ContentWrapper'
import { FormButton, FormInput1, FormLabel, FormText, FormWrapper } from '../../CustomForm/CustomFormElements'
import Footer from '../../Footer'
import { Header } from '../../Header'
import { HoverOption } from '../../MenuOptions/MenuOptionsElements'
import { useModal } from '../../Modal/ModalContext'
import { SongDetailsText } from '../../MusicUploadsForm/MusicUploadsFormElements'
import { SmallText } from '../../NFTCollectionCreator/NFTCollectionCreatorElements'
import OptionModal from '../../OptionModal'
import PageIndexer from '../../PageIndexer'
import QRCodeForm from '../../QRCodeForm'
import ScrollToTop from '../../ScrollToTop'

const RegistrationForm = ({artist, discography}) => {
    const {openModal, closeModal, modalContent} = useModal()
    const {addAlert} = useAlert()
    const {goTo} = ScrollToTop()
    const [artistName, setName] = useState(artist?.profile?.name ? artist?.profile?.name : '')
    const [artistId, setArtistId] = useState(artist?.artistId ? artist?.artistId : undefined)
    const [fullName, setFullName] = useState(artist?.profile?.fullName ? artist?.profile?.fullName : '')
    const [address, setAddress] = useState(artist?.profile?.uid ? artist?.profile?.uid : '')
    const [accInfo, setAccInfo] = useState()
    const [paymentPointer, setPaymentPointer] = useState(artist?.profile?.paymentPointer ? artist?.profile?.paymentPointer : '')
    const [email, setEmail] = useState(artist?.profile?.email ? artist?.profile?.email : '')
    const [nftStorageApiKey, setNftStorageApiKey] = useState(artist?.bio ? artist?.bio : '')
    const [bio, setBio] = useState(artist?.profile?.bio ? artist?.profile?.bio : '')
    const [errors, setErrors] = useState({})
    const [currentPage, setCurrentPage] = useState(1)

    const handleInput = (e) => {
        switch (e.target.name) {
            case ARTIST_FORM_INPUT_TYPES.NAME:
                setName(e.target.value)
                
                break;
            case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
                setFullName(e.target.value)

                break;
            case ARTIST_FORM_INPUT_TYPES.EMAIL:
                setEmail(e.target.value)

                break;
            case ARTIST_FORM_INPUT_TYPES.PAYMENT_POINTER:
                setPaymentPointer(e.target.value)
                break;
            case ARTIST_FORM_INPUT_TYPES.NFT_STORAGE_API_KEY:
                setNftStorageApiKey(e.target.value)
                break;
        
            default:
                break;
        }
    }

    const onAddressSuccess = (accInfo) => {
        //console.log(accInfo)
        setAddress(accInfo?.account?.Account)
        closeModal()
        modalContent()
    } 

    const onAddressFail = () => {
        closeModal()
        modalContent()
    }

    const changeAddress = () => {
        openModal()
        modalContent(<QRCodeForm 
            heading={'Artist Address Registration'}
            type={TRANSACTION_OPTIONS.CHANGE_ARTIST_LABEL_ADDRESS}
            body={{
                memo: address ? 'Artist is signing transaction to change login wallet address' : 'Artist is signing transaction to add login wallet address' ,
                instruction: address ? 'Artist is signing transaction to change login wallet address' : 'Artist is signing transaction to add login wallet address' ,
            }}
            onSuccess={onAddressSuccess}
            onFail={onAddressFail}
            cancelQr={() => {
                closeModal()
                modalContent()
            }}
            />)
    }

    const verifyInfo = () => {
        let err = {}
        setErrors()
        if(artistName?.trim()?.length === 0){
            err = {
                ...err,
                name: 'Artist name cannot be empty'
            }
        }
        if((email?.trim()?.length === 0) || !emailRegex.test(email)){
            err = {
                ...err,
                email: 'Please enter a valid email address'
            }
        }
        if(paymentPointer?.trim().length > 0 && !paymentPointer.includes('ilp.')){
            err = {
                ...err,
                paymentPointer: 'Payment Pointer is invalid'
            }
        }
        if((address?.trim()?.length === 0)){
            err = {
                ...err,
                walletAddress: 'Please add a wallet address'
            }
        }
        setErrors(err)
        if(getObjectLength(err) === 0){
            return true
        }
        if(getObjectLength(err) > 0){
            return false
        }
    }

    const onRegistrationFail = () => {
        closeModal()
        modalContent()
    }


    const onRegistrationSuccess = () => {
        goTo('/home')
        modalContent(<>
            <OptionModal heading={artist?.artistId  ? 'Artist Account Claim':'Artist Account Registration'}>
                <FormText>Your artist {artist?.artistId ? `claim` : 'registration'} is being processed. You will receive an email shortly. </FormText>
                <HoverOption onClick={() => {
                    closeModal()
                    modalContent()
                }}>Ok</HoverOption>
            </OptionModal>
        </>)
        
    }



    const submitCreate = () => {
       if(verifyInfo()){
        openModal()
        modalContent(<QRCodeForm 
            heading={artist?.artistId  ? 'Artist Account Claim':'Artist Account Registration'}
            type={TRANSACTION_OPTIONS.ARTIST_REGISTRATION}
            body={{
                sender: address,
                profile: {
                    artistId: artistId,
                    name: artistName,
                    uid: address,
                    email: email,
                    paymentPointer: paymentPointer,
                    fullName: fullName,
                    nftStorageAPIKey: nftStorageApiKey,
                    bio: bio.trim().length > 0 ? bio : undefined,
                    discography: discography

                },
                memo: artist?.artistId  ? 'Artist is signing transaction to claim account for ' + artistName : 'Artist is signing transaction to register account for ' + artistName ,
                instruction: artist?.artistId  ? 'Artist is signing transaction to claim account for ' + artistName : 'Artist is signing transaction to register account for ' + artistName ,
            }}
            onSuccess={onRegistrationSuccess}
            onFail={onRegistrationFail}
            cancelQr={() => {
                closeModal()
                modalContent()
            }}
            />)
       }else{
        addAlert({
            title: "Artist Registration",
            type: ALERT_TYPES.WARNING,
            message: 'Please fill in form',
            secs: 10,
          });
       }
    }

  return (
    <>
    <Header title={'Artist Registration'}/>
    <ContentWrapper>
      { currentPage === 1 && <>
      
      <FormWrapper>
            <FormLabel>Artist Name</FormLabel>
            <FormInput1 name={ARTIST_FORM_INPUT_TYPES.NAME} value={artistName} onChange={handleInput} placeholder={'Artist Name'}/>
            <SmallText>Artists Stage Name. This will be used for displaying in your profile and music</SmallText>
            {errors?.name && <SongDetailsText error >{errors?.name}</SongDetailsText>}
        </FormWrapper>
        <FormWrapper>
        <FormLabel>Full Name (Optional)</FormLabel>
            <FormInput1 name={ARTIST_FORM_INPUT_TYPES.FULL_NAME} value={fullName} onChange={handleInput} placeholder={'Full Name'}/>
            <SmallText>Artists full (real) name. This will be used for any music credits</SmallText>
        </FormWrapper>
        <FormWrapper>
        <FormLabel>Email Address</FormLabel>
            <FormInput1 name={ARTIST_FORM_INPUT_TYPES.EMAIL} type={"email"} value={email} onChange={handleInput} placeholder={'Email Address'}/>
            <SmallText>Email Address to cantact artist. Gravatar account information from this address will be used within the artist profile </SmallText>
            {errors?.email && <SongDetailsText error>{errors?.email}</SongDetailsText>}
        </FormWrapper>
               
        </>}

        {currentPage === 2 && 
            <> <FormWrapper>
            <FormLabel>Wallet Address</FormLabel>
                <FormInput1 name={ARTIST_FORM_INPUT_TYPES.ADDRESS} readOnly value={address} placeholder={'Wallet Address'}/>
                <Button onClick={() => changeAddress()}>{address ? `Change Address` : `Sign In` }</Button>
                <SmallText>A wallet address that will be used to access the artist page . This will be used for receiving payments and signing artist related transactions</SmallText>

                {errors?.walletAddress && <SongDetailsText error>{errors?.walletAddress}</SongDetailsText>}
            </FormWrapper>
            <FormWrapper>
            <FormLabel>Payment Pointer (Optional)</FormLabel>
                <FormInput1 name={ARTIST_FORM_INPUT_TYPES.PAYMENT_POINTER} value={paymentPointer} onChange={handleInput} placeholder={'Payment Pointer'}/>
                <SmallText>This will be used for receiving micropayments and tips using the Interledger Protocol</SmallText>
                {errors?.paymentPointer && <SongDetailsText error>{errors?.paymentPointer}</SongDetailsText>}
            </FormWrapper>
            <FormWrapper>
            <FormLabel>NFT Storage API Key (Optional)</FormLabel>
                <FormInput1 name={ARTIST_FORM_INPUT_TYPES.NFT_STORAGE_API_KEY} value={nftStorageApiKey} onChange={handleInput} placeholder={'NFT Storage API Key'}/>
                <SmallText>An API key from artists NFT Storage bucket. This will be used for uploading NFT metadata and music information to artist's storage bucket. This allows the artist to still have complete ownership of their NFT metadata and music.</SmallText>
            </FormWrapper></>
        }
        {currentPage === 3 && 
            <><FormWrapper>KYC Coming Soon</FormWrapper></>
        }
        <PageIndexer onClick={setCurrentPage} pages={2} currentPage={currentPage}/>
        <ButtonWrapper>
            <Button onClick={() => submitCreate()}>Create Account</Button>
            <CustomButton onClick={() => {goTo('/artists/registration')}}>Cancel</CustomButton>
        </ButtonWrapper>
    </ContentWrapper>
    
    </>
  )
}

export default RegistrationForm