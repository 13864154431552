import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const MEDIA_SCREEN = { //Screen sizes for devices
    MOBILE: '480',
    TABLETS: '768',
    LAPTOPS: '1024',
    DESKTOPS: '1025',
    TV: '1500'
  }

export const Container = styled.div`
    display: flex;
    width: 100%;
    background: ${props => props?.theme?.background ? props?.theme?.background : 'black'};

    transition: all 0.5s ease-in-out;
    height: 100%;
`

export const Main = styled.div`
display: block;
width: 100%;
background: ${props => props?.theme?.background ? props?.theme?.background : 'black'};

flex-direction: column;
width: 93.6%;
transition: all 0.2s ease-in-out;
@media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px)
{
    margin: 0;
    width: 100%;
}

`


export const SidebarContainer = styled.div`
    flex-direction: column;
    max-height: 100%;
    display: flex;
    margin-right: 3%;
    flex-grow: 1;
    background: ${(props) => hexToRgba(props.theme.mainBg, 0.5)};
    color: ${props => props.theme.secondaryBg};
    transition: all 0.5s ease-in-out;
    z-index: 100;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px)
    {
        display: none;
    }

 `

export const SidebarWrapper = styled.div`
    position: fixed;
    padding: 2%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(8px);
    background: ${(props) => hexToRgba(props.theme.mainBg, 0.5)};
    flex-direction: column;
    display: flex;
    transition: all 0.2s ease-in-out;
    border-right: groove 1px ${props => hexToRgba(props.theme.secondaryBg,0.5)};
    height: 100vh;
`


export const SidebarTopSection = styled.section`
    display: flex;
    align-items: center;
    padding: 2% 1.5%;

`

export const SidebarLogo = styled.div`
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1%;
    height: 2.1rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-weight: 700;

    &:hover{
    transform: scale(1.07);
    transition: 1s ease-out;
    }
`

export const NavImg = styled.img`
    display: ${ props => props.isOpen ? 'flex' : 'none'};
  height: 100%;
  position: relative;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: rotateY(360deg);
    transition: 1s ease-out;
  }
`;

export const SidebarMenu = styled.ul`
    display: flex;
    margin-top: 1%;
    align-content: center;
    padding: 2%;
    width: 100%;
    transition: all 0.2s ease-in-out;
    flex-direction: column;
    justify-content: flex-start;
    color: ${props => props.theme.secondaryBg};
    
`

export const SidebarLink = styled(Link)`
    display: flex;
    width: 100%;
    border-radius: 25px;
    color: ${(props) => props.isActive ? props.theme.accentColor : props.theme.secondaryBg};
    align-items: center;
    text-decoration: ${(props) => props.isActive ? 'bold' : 'none'};
    list-style: none;
    transition: 0.2s ease-in-out;
    cursor:pointer;
    padding: 4% 0 ;
    &:hover {

        transition: all 0.2s ease-in-out;
        color:  ${props => props.theme.accentColor};
    }
    &:active {
        border-radius: 25px;
        transition: all 0.2s ease-in-out;
        color:  ${props => props.theme.mainColor};
    }
    @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px)
    {
        padding: 5% 0 ;
    }
`

export const SideBarProfileIcon = styled.img`
    width: ${(props) => props.isOpen ? '1.2rem' : '2rem'};
    height: ${(props) => props.isOpen ? '1.2rem' : '2rem'};
    border-radius: 50%;
`

export const SideBarLinkIcon = styled.div`
    font-size: ${ props => props.isOpen ? '1.2rem' : '2rem'};
    padding: 2%;
    transition: all 0.2s ease-in-out;
    margin-right: 3%;
    cursor:pointer;
`
export const SideBarLinkText = styled.div`
    font-size: 1.1rem;
    cursor:pointer;
    transition: all 0.2s ease-in-out;
    display: ${ props => props.isOpen ? 'flex' : 'none'};
`

export const SidebarBtnLink = styled(Link)`
  display: flex;
  white-space: nowrap;
  border-radius: 50px;
  font-size: ${ props => props.isOpen ? '0.8rem' : '2rem'};
  background-color: ${(props) => props.isOpen ? props.theme.mainColor : 'transparent'};
  padding: ${(props) => props.isOpen ? '5% 1rem' : '0'};
  color: ${(props) => props.theme.secondaryBg};
  border:  ${ props => props.isOpen ? `2px solid ${props.theme.mainColor}`: 'none'};
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-bottom: 5%;
  align-items: center;
  justify-content: center;
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: ${(props) => props.isOpen ? props.theme.secondaryBg : 'transparent'};
    border: ${ props => props.isOpen ? `2px solid ${props.theme.secondaryBg}`: 'none'};
    color: ${(props) => props.theme.mainColor};
  }
`;