import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import LabelRegistration from '../../components/RegistrationForm/LabelRegistration';
import Footer from '../../components/Footer';
import { Home, Welcome } from '../../components/Navbar/Data';

const RecordLabelRegistrationPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const navigate  = useNavigate()
    return (
        <>
         
        <Navbar toggle={toggle}  navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
    
          <Sidebar
                isOpen={isOpen}
                toggle={toggle}
                navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
              />
          <LabelRegistration/>
        
        </>
      )
}

export default RecordLabelRegistrationPage